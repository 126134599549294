<template>
  <ProductsView v-if="equipmentContent && equipment" :content="equipmentContent" :products="equipment" />
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ProductsView from '@/components/ProductsView'

export default {
  name: 'Equipment',
  title: 'Equipos | Andinor',
  components: { ProductsView },
  computed: {
    ...mapState(['equipmentContent']),
    ...mapGetters(['equipment'])
  },
  created () {
    this.getEquipmentContent()
  },
  methods: {
    ...mapActions(['getEquipmentContent'])
  }
}
</script>
